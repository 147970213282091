<template>
  <div class="page-content">
    <el-row>
      <el-col :span="24">
        <jwczbtzc-block></jwczbtzc-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zxzczc-block></zxzczc-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import JwczbtzcBlock from "./JwczbtzcBlock";
import ZxzczcBlock from "./ZxzczcBlock";

export default {
  name: "ZcfgHome",
  components: {
    JwczbtzcBlock,
    ZxzczcBlock
  }
}
</script>

<style scoped>

</style>
