<template>
  <block title="境外参展补贴政策">
    <div style="max-height: 400px;overflow: auto;padding: 10px 0;">
      <el-timeline>
        <el-timeline-item
            v-for="(activity, index) in data"
            :key="index"
            :timestamp="activity.time" hide-timestamp>
          <div class="jwczbtzc-timeline-item">
            <div class="jwczbtzc-timeline-item-time">{{ activity.time }}</div>
            <div class="jwczbtzc-timeline-item-province">{{ activity.province }}</div>
            <div class="jwczbtzc-timeline-item-tc">
              <a class="jwczbtzc-timeline-item-title link" :href="activity.url" target="_blank">{{ activity.title }}</a>
              <div class="jwczbtzc-timeline-item-content">{{ activity.content }}</div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </block>
</template>

<script>
import Block from "./Block";
import {jwczbtzc} from '../api/zc_data'

export default {
  name: "JwczbtzcBlock",
  components: {Block},
  data() {
    return {
      data: [...jwczbtzc].sort((a,b)=>b.time.localeCompare(a.time))
    }
  }
}
</script>

<style scoped>

</style>
