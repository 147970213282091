<template>
  <div class="page-content">
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">地区：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">内容：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.keyword" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">发字文号：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.postNameLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">时间：</div>
          <div class="search-form-field-content">
            <el-date-picker style="width: 250px;margin-left: 10px;" clearable
                            v-model="searchForm.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期" size="small" unlink-panels
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">发布机构：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.releaseByClassifyLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>

    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 25px;" v-if="data">
      <el-table :data="content" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column prop="title" label="政策标题" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="row.url" target="_blank">{{row.title}}</a>
        </el-table-column>
        <el-table-column prop="area" label="地区" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="source" label="来源站点" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="release_by_classify" label="发布机构" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="release_time" label="发布时间" width="120" show-overflow-tooltip>
          <span slot-scope="{row}">{{row.release_time.substring(0,10)}}</span>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import TagSelect from "./TagSelect";
import moment from "moment";
import Collapse from "./Collapse";
const DATE_FMT='YYYY-MM-DD';

export default {
  name: "ZcfgList",
  components:{TagSelect,Collapse},
  data(){
    return {
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      searchForm:{
        dateRange:null,
        releaseByClassifyLike:null,
        keyword:null,
        postNameLike:null,
      },
      searchParams:{
        area:null,
        startDate:null,
        endDate:null,
        releaseByClassifyLike:null,
        keyword:null,
        postNameLike:null,
      },
      data:null
    }
  },
  created() {
    this.init()
  },
  computed:{
    content(){
      return this.data?this.data.content.map(i=>i.data):[]
    }
  },
  methods:{
    async init(){
      await this.search()
    },
    async search(){
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.area=null
      }else{
        this.searchParams.area=province
      }
      if (this.searchForm.dateRange) {
        this.searchParams.startDate = moment(this.searchForm.dateRange[0]).format(DATE_FMT)
        this.searchParams.endDate = moment(this.searchForm.dateRange[1]).format(DATE_FMT)
      }else{
        this.searchParams.startDate = null
        this.searchParams.endDate = null
      }
      this.searchParams.releaseByClassifyLike=this.searchForm.releaseByClassifyLike
      this.searchParams.keyword=this.searchForm.keyword
      this.searchParams.postNameLike=this.searchForm.postNameLike
      await this.loadData(1)
    },
    async reset(){
      this.areaAndProvinces.forEach(i=>i.selected=[])
      this.searchForm.dateRange=null
      this.searchForm.releaseByClassifyLike=null
      this.searchForm.keyword=null
      this.searchForm.postNameLike=undefined
    },
    async loadData(page){
      this.data=(await api.listZcwj({...this.searchParams,page:page-1,size:10})).data
    }
  }
}
</script>

<style scoped>

</style>
