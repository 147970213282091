<template>
  <block title="最新支持政策">
    <el-table :data="data" max-height="400" :span-method="objectSpanMethod" class="large-font-table">
      <el-table-column label="地区" width="100">
        <span slot-scope="{row}">{{ row.province }}{{ row.city }}</span>
      </el-table-column>
      <el-table-column label="政策">
        <a slot-scope="{row}" :href="row.url" target="_blank" class="link">{{ row.title }}（{{ row.time }}）</a>
      </el-table-column>
    </el-table>
  </block>
</template>

<script>
import Block from "./Block";
import {hzzczc} from '../api/zc_data'

export default {
  name: "ZxzczcBlock",
  components: {Block},
  data() {
    return {
      data: [...hzzczc]
    }
  },
  methods: {
    objectSpanMethod({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        const currentRow = this.data[rowIndex]
        const prevRow = rowIndex === 0 ? null : this.data[rowIndex - 1]
        if (!prevRow || (prevRow.province !== currentRow.province || prevRow.city !== currentRow.city)) {
          let rowspan = 1
          for (let i = rowIndex + 1; i < this.data.length; i++) {
            const nextRow = this.data[i];
            if (nextRow.province === currentRow.province && nextRow.city === currentRow.city) {
              rowspan++;
            } else {
              break
            }
          }
          return {
            rowspan,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
