export const jwczbtzc = [{
    "province": "北京市",
    "time": "2020-04-08",
    "title": "北京市商务局关于申报2020年度外经贸发展资金项目的通知",
    "content": "北京市对参加境外展览会的企业提供展位费50%-70%的补贴，不包括企业注册费和展位搭建费。\n30000元/每个展位（9平米），企业可申请多个展位，补贴总额不超过30万元。对于大型展品回运费，单个展品达到体积1立方米且重量1吨以上，可以申请补贴，上限为50%，10万元。\n*支持比例一般为支持内容所需金额的50%，拓展面向拉美、非洲、中东、东欧、东南亚和中亚等新兴国际市场的支持比例可提高到70%。",
    "url": "https://www.beijing.gov.cn/zhengce/gfxwj/sj/202004/t20200410_1800033.html"
}, {
    "province": "黑龙江省",
    "time": "2023-05-06",
    "title": "黑龙江省支持对外贸易发展的若干措施",
    "content": "给予企业参加线下境外展会所产生的标准展位费70%补贴（每个展会不超过四个标准展位，每个标准展位最高不超过5000元补贴，每个展会最高不超过2万元补贴）。\n给予企业参加境外展会所产生展品运输费70%补贴。\n对每个展会不超过2名参展人员发生的国际间交通费，按经济舱标准给予70%补贴。住宿费按不超过国家规定公务人员出国标准给予70%补贴。",
    "url": "https://sswt.hlj.gov.cn/sswt/c103658/202305/c00_31601393.shtml"
}, {
    "province": "吉林省",
    "time": "2022-10-13",
    "title": "关于进一步加强对吉林省外经贸企业参加国际性展会管理与支持的通知",
    "content": "单次使用前两个标准展位给予全额支持，第三个标准展位及以上减半支持。单个展位支持不超过5万元，单次支持不超过15万元，全年支持不超过60万元。\n单次境外展会产生的展品运输费补贴不超过1万元，全年累计不超过5万元。\n对人员费用补贴：实际发生费用低于支持标准的，按实际发生费用支持；超过支持标准的，按支持标准的金额予以支持。单次参加展会给予不超过2人次的费用支持；全年累计不超过10人次。\n*前往俄罗斯及其他独联体国家，除阿穆尔州、滨海边疆区、哈巴罗夫斯克边疆区为6000元/人外，补贴标准均为12000元/人。",
    "url": "http://xxgk.jl.gov.cn/zcbm/fgw_98057/xxgkmlqy/202301/W020230131323813766985.pdf"
}, {
    "province": "辽宁省",
    "time": "2023-01-27",
    "title": "辽宁省进一步稳经济若干政策举措",
    "content": "参加境外展览会（团体）项目。对企业参加经省政府批准由省有关部门统一组织的重点境外展览会，对参展企业当年前6个标准展位的展位费（按金额高低排序）给予100%补助；6个展位以外的其他展位费给予50%以下资金支持。",
    "url": "http://www.shenyang.gov.cn/zt/hqlmzc/lnszc/202304/t20230412_4447390.html"
}, {
    "province": "浙江省",
    "time": "2022-07-07",
    "title": "浙江省商务展会及经费管理办法",
    "content": "浙江省商务厅及浙江省财政厅于2022年7月7日发布《浙江省商务展会及经费管理办法》，其中，企业参加重点类展会的国际贸易展会，支持标准为展位费：最高补助70%，单个展位最高不超过5万元，单个展会单个企业最高不超过15万元。",
    "url": "http://zcom.zj.gov.cn/art/2022/7/7/art_1229268085_2411033.html"
}, {
    "province": "浙江省",
    "city": "杭州市",
    "time": "2019-03-28",
    "title": "杭州市加快服务贸易创新发展实施意见",
    "content": "对参加《杭州市服务贸易展会目录》（每年由市商务局公布）所列境内外展会的服务贸易企业，根据展会情况按照最高不超过70%、100%的比例分类予以一定的注册费和展位费补助。\n其它商务局认可的服务贸易类展会，对参展企业按不超过注册费和展位费的50%给予补助。\n展位费补助标准：同一展会每家企业最多补助6个标准展位，展位费超过5万元/标准展位的，按5万元计算；注册费补助，对有展位的参展企业最多补助2人，对无展位的参展企业最多补助1人。",
    "url": "http://www.hangzhou.gov.cn/art/2019/3/28/art_1229063383_1759399.html"
}, {
    "province": "浙江省",
    "city": "温州市",
    "time": "2022-12-22",
    "title": "温州市支持企业\u201c留员工、稳生产、开门红\u201d工作方案",
    "content": "温州市人民政府办公室于2022年12月22日发布《温州市支持企业\u201c留员工、稳生产、开门红\u201d工作方案》其中，针对企业2023年一季度赴境外参展的，已明确费用补助标准：境外展在亚洲区域的，每人给予8000元补助；亚洲之外的，每人给予1.5万元补助，每个展会每家企业最高不超过4人次。\n企业赴RCEP其他成员国开展重大商业活动（除参展外），每人出入境机票费用给予30%的补助。",
    "url": "https://www.wenzhou.gov.cn/art/2022/12/29/art_1229117830_2018538.html"
}, {
    "province": "浙江省",
    "city": "绍兴",
    "time": "2023-01-09",
    "title": "绍兴市支持企业抢单拓市若干政策",
    "content": "绍兴市出台《绍兴市支持企业抢单拓市若干政策操作细则》，进一步支持企业抢单拓市。政策明确，2023年一季度，绍兴企业参加境外展会、出境开展商务活动、邀请外商入境开展商业洽谈等，可以获得相应出入境机票补助、展位费补助、客商邀请奖励等。\n根据政策，绍兴对企业参加市年度重点境外展会（含线上展会）的，给予展位费100%补助；对企业参加非市年度重点境外展会（含线上展会）的，给予展位费50%补助；展会在\u201c一带一路\u201d沿线国家和地区的，给予展位费60%补助；展会在RCEP成员国家的，给予展位费70%补助。以上同一项目均需扣除上级补助资金，每家企业补助总额不超过 100万元。",
    "url": "http://www.sx.gov.cn/art/2023/1/9/art_1229265242_1880698.html"
}, {
    "province": "浙江省",
    "city": "嘉兴市",
    "time": "2022-12-19",
    "title": "嘉兴市关于助推工业经济2023年一季度\u201c开门稳、开门好\u201d的若干政策",
    "content": "给予2023年一季度赴境外参展企业人员往返机票费用50%的补助，单家企业最高不超过6万。\n对2023年一季度企业人员赴境外参展的，给予摊位费全额补助，每个标准摊位最高5万元，每家企业同一个展会最高补助3个标准摊位。",
    "url": "https://www.jiaxing.gov.cn/art/2022/12/19/art_1229426376_45403.html"
}, {
    "province": "浙江省",
    "city": "金华市",
    "time": "2022-07-15",
    "title": "促进金华市开放型经济高质量发展政策",
    "content": "支持自主举办展会拓市场。对参加列入年度展会目录中，由市商务部门（贸促会、国际商会）自主举办的境内外展会和线上展会，给予展位费100%资助。\n支持企业参加各类展会拓市场。对参加年度展会目录中的重点境内国际展会和线上展会，给予展位费最高50%的资助。对企业参加非自主举办的境外展会（含代参展），给予按每个标准展位给予最低60%的资助。单个展位费资助最高不超过3万元，每家企业同一展会最高资助4个展位，同一企业全年展位费（不含自办展）资助最高不超过30万元。\n年度展会目录每年动态调整，由市商务部门确定并公布。",
    "url": "http://swj.jinhua.gov.cn/art/2022/7/15/art_1229169318_58921732.html"
}, {
    "province": "福建省",
    "city": "福州市",
    "time": "2023-01-20",
    "title": "关于促进当前工业经济稳增长的措施",
    "content": "鼓励我市2022年有进出口实绩的外贸企业参加省、市牵头组织的组团赴境外参加线下展、拜访客户等活动。对企业参与省、市组团赴境外参展、拜访客户的机票费用给予50%补助。\n对企业参与境外展的展位费给予70%补助。此政策与省级政策可叠加，总补助不超出企业实际支出，单家企业单场展会市级补助最高不超过5万元。",
    "url": "http://jn.fuzhou.gov.cn/zz/gzdt/tztg/202301/t20230120_4526799.htm"
}, {
    "province": "福建省",
    "city": "泉州市",
    "time": "2022-11-14",
    "title": "泉州市贯彻落实福建省推动民营经济创新发展若干措施的工作方案",
    "content": "《泉州市贯彻落实福建省推动民营经济创新发展若干措施的工作方案》的通知中：\n鼓励通过委托境外合作伙伴、代理商等方式参加国外线下展会，对企业展位费按最高不超过70%进行补助，单个展位补助最高不超过4万元；鼓励包机抱团赴国外开拓国际市场，对参加包机活动的参展或开展商务洽谈企业人员的包机费用按最高不超过50%进行补助。\n积极组织开展线上、线下展会，对县（市、区）政府或行业协会举办（含承办）的行业性线下、线上展会，每场展会分别给予30万元、10万元补助。",
    "url": "http://www.quanzhou.gov.cn/zfb/xxgk/zfxxgkzl/ztzl/2023001/zcfb/swbgs/202301/t20230127_2840646.htm"
}, {
    "province": "福建省",
    "city": "厦门市",
    "time": "2023-02-05",
    "title": "大力提振市场信心推动经济平稳向好若干措施的通知",
    "content": "对2023年上半年外贸企业赴境外参展，展位费补助标准提高到A类25000元，B类20000元，参加我市\u201c一带一路\u201d9个重点市场、\u201c一国一策\u201d8个重点市场及金砖国家的展位费补助标准上浮20%。\n参展人员费用补助提高为A类10000元，B类5000元。\n对2023年上半年参加香港重要展会（以白名单方式明确），展位费补助标准提高为18000元，参展人员费用补助提高为2500元。",
    "url": "https://www.xm.gov.cn/zwgk/flfg/sfwj/202302/t20230205_2717747.htm"
}, {
    "province": "山东省",
    "time": "2023-01-10",
    "title": "山东省商务厅关于印发2023年境外百展市场开拓计划的通知",
    "content": "2023年拟安排展会项目130个，其中主办类10个，重点推荐类120个。\n继续给予财政政策支持。为进一步扩大企业受益覆盖面，对2023年主办类展会和各市组织的重点类展会展位费给予不低于80%的补贴。\n主办类展会使用省级专项资金给予支持对企业参加展会的展位费、展位搭建、展品运输等给予展前补贴，对展会宣传推介、客户邀请费等给予支持；各市组织的重点类展会统筹使用中央外经贸发展专项资金和市级专项资金给予支持，对企业参展发生的展位费、展位搭建、展品运输等给予展后补贴。",
    "url": "http://commerce.shandong.gov.cn/art/2023/1/10/art_16081_10315010.html"
}, {
    "province": "上海市",
    "time": "2023-06-12",
    "title": "中小企业国际市场开拓资金支持内容及申报要求",
    "content": "企业应按实际参展面积申请项目，展位费和公共布展费每9平方米最高限额分别为15000元和3000元。单个展会每个企业最高可申请27平方米，展位费和公共布展费最高支持限额分别为45000元和9000元。对小于27平方米的，支持限额应按每平方米支持限额进行折算（四舍五入后取整）。\n项目组织单位申请境外展览会（团体项目）时，应提供参展企业汇总表，参展的中小企业应在10家（含10家）以上。",
    "url": "https://sww.sh.gov.cn/cmsres/aa/aac228f484834ad6bef53e5b420e1d75/4e5f4ad20e6456db0fb2630b18d35825.doc"
}, {
    "province": "上海市",
    "city": "青浦区",
    "time": "2023-04-21",
    "title": "青浦区2023年度开拓国内市场（展会补贴）扶持资金项目申报通知",
    "content": "对企业参加经相关主管部门批准的国内展览会，经评审符合条件的参展项目给予展位费50%的补贴，按实际参展标准展位（9平方米）个数申请项目，单个展会每个企业最高可申请3个标准展位支持，单个标准展位最高限额15000元，单户企业每年补贴额最高不超过10万元。",
    "url": "https://www.sh-hitech.com/kjcx/7337.html"
}, {
    "province": "广东省",
    "time": "2023-02-27",
    "title": "广东省商务厅关于做好2023年\u201c粤贸全球\u201d广东商品境外展览平台相关工作的通知",
    "content": "选定了一批线下、线上展会平台作为2023年重点打造的\u201c粤贸全球\u201d广东商品境外展览平台，下一步将给予政策上的重点支持。",
    "url": "http://com.gd.gov.cn/zwgk/ywtz/content/post_4101917.html"
}, {
    "province": "陕西省",
    "time": "2023-01-06",
    "title": "陕西省商务厅关于2023年重点境外展会活动的有关通知",
    "content": "对省商务厅或委托第三方机构牵头组织的A类展会，给予展位费（龙头企业每个展会支持不超过2个展位，中小企业补贴1个展位）、公共搭建费、展品运输费全额补贴（每户企业不超过0.5万元），补贴将使用省级外经贸发展专项资金给予支持。展会活动的补助部分统一由承办单位先行垫付，展会结束后，承办单位提交的相关资料经我厅审核通过一次性拔付。\n对企业自行参加境外B类展会，展位费给予不超过85%的补贴。",
    "url": "http://jhj.hanzhong.gov.cn/hzjhjwz/zsxw/202301/f13c5475186443c3aad539cfdbedb393.shtml"
}, {
    "province": "四川省",
    "time": "2023-02-06",
    "title": "聚焦高质量发展推动经济运行整体好转的若干政策措施",
    "content": "《聚焦高质量发展推动经济运行整体好转的若干政策措施》，提到开展\u201c川行天下\u201d国际市场拓展活动，支持超100场重点国际性展会和经贸活动，对参加境内外国际性展会的企业给予支持。",
    "url": "http://www.cdht.gov.cn/cdht/c149031/2023-02/27/content_7672939443dc4a3382cd537640a39d16.shtml"
}, {
    "province": "湖南省",
    "time": "2023-03-01",
    "title": "2023年湖南省重点境外展会目录",
    "content": "按照不超过展位费的70%的标准给予补贴支持，单个企业单次展会单个展位补贴金额不超过5万元，在此基础上每增加1个展位增加1.5万元，最多支持3个展位，合计不超过8万元。\n为助推乡村振兴重点帮扶县外贸发展，对15个乡村振兴重点帮扶县的参展企业，展位费予以全额补贴，不设展位数量和支持金额限制。",
    "url": "https://swt.hunan.gov.cn/swt/hnswt/tzgg/202303/t20230301_572645773536419648.html"
}, {
    "province": "云南省",
    "time": "2022-07-18",
    "title": "应对疫情影响促进展会活动恢复发展若干措施（试行）",
    "content": "云南省商务厅关于印发《应对疫情影响促进展会活动恢复发展若干措施（试行）》的通知：\n境外参展补贴以实际产生的展位费（室内展位不超过30平方米，室外光地展位不超过100平方米）、布展费、国际航班经济舱交通费和人员费用总额为基准，给予不超过70%的支持，人员出境时限、出境天数、出访国家（地区）上限和费用标准参照行政人员出国（境）标准。",
    "url": "https://swt.yn.gov.cn/uploads/attachments/01a53cab-380d-4f28-a32d-65853b4ce753.pdf"
}, {
    "province": "江苏省",
    "time": "2023-01-18",
    "title": "关于推动经济运行率先整体好转的若干政策措施",
    "content": "江苏省政府正式印发了《关于推动经济运行率先整体好转若干政策措施》其中第28条提到：支持企业参加超100个境外线下重点展会，统筹中央和省级资金对出境参展企业展位费给予最高80%的补助。\n《江苏省商务厅2023年贸易促进计划》中，列入计划的展会共有253个，其中\u201c江苏优品\u2022畅行全球\u201d线上国际展会4个、\u201c苏新服务\u2022智惠全球\u201d线上对接会15个，引导外贸企业拓展线上开拓国际市场渠道，获取更多订单；境外线下重点展114个，推动重点行业巩固传统市场，用足用好RCEP等自贸协定，加大力度开拓\u201c一带一路\u201d沿线国家等新兴市场；\n境外线下展会参考目录92个，引导地方结合产业优势和企业需求优化市场布局；线下境内机制性展会26个、跨境电商专题展会2个，引导企业多元开拓国际国内两个市场，促进内外贸一体化发展。",
    "url": "http://www.jiangsu.gov.cn/art/2023/1/18/art_84418_10729608.html"
}, {
    "province": "江苏省",
    "city": "南京市江北新区",
    "time": "2023-04-06",
    "title": "南京江北新区加快会展业高质量发展若干措施",
    "content": "2023年，南京市江北新区出台了：关于印发《南京江北新区加快会展业高质量发展若干措施》的通知：对由全国性的学会、协会等社会组织，中央直属企业，世界500强企业、国内民营经济百强企业（上年度排名）等机构或企业举办的专业会议，会期不少于2天且不少于200间夜，一次性给予举办单位10万元的扶持；每增加100间夜补贴10万元，每届不超过50万元。\n对符合新区主导产业的全国性高端会议扶持金额上浮10%。对与新区会展场馆运营机构签订3年以上合作协议的，最后一年额外给予20万元奖励。\n同时，对在新区举办纳入国际大会及会议协会（ICCA）统计范围的会议，一次性给予30万元奖励。对新区注册的企业在新区举办的展会项目取得全球展览业协会（UFI）认证的，认证之后给予50万元的一次性奖励。",
    "url": "http://njna.nanjing.gov.cn/tzxq/tzzc/xqdtzzc/202304/t20230406_3881158.html"
}, {
    "province": "天津市",
    "time": "2023-05-08",
    "title": "2023 年天津市企业提升国际化经营能力项目申报指南",
    "content": "根据2023年《天津市企业提升国际化经营能力项目申报指南》的通知中显示：\n对企业参加境外展会9平方米展位最高支持比例50%，最高支持限额每个标准展位15000元，每个展会项目最多支持4个标准展位，对参加《优先支持国家（地区）目录》展会提高支持比例，如巴基斯坦、阿联酋、南非及肯尼亚等国家和地区，最高支持比例不超过70%，每个标准展位的最高支持限额为21000元。",
    "url": "https://shangwuju.tj.gov.cn/tjsswjzz/zmhd/jcyjzj/202212/W020221224373535210786.pdf"
}, {
    "province": "河南省",
    "time": "2023-02-13",
    "title": "大力提振市场信心促进经济稳定向好政策措施",
    "content": "河南省商务厅、省财政厅联合出台支持外贸平稳发展一揽子政策，就助力企业开拓市场、高质量发展、应对贸易摩擦等进一步加大资金支持力度。\n对企业参加境外国际性展会的净展位费、人员机票费实施分类补助，最高补贴为90%。值得关注的是，疫情防控期间，对使用代参展服务的企业发生的展位费同样给予同等标准支持。\n此外，每个企业每年可申请5个线上展会支持项目，所发生费用最高可补贴50%，最高不超过8000元。企业参加省商务厅发布的重点出口转内销展会，每个展会可获补贴1万元。",
    "url": "https://hnsswt.henan.gov.cn/2023/02-13/2688284.html"
}, {
    "province": "海南省",
    "city": "琼海市",
    "time": "2023-06-05",
    "title": "琼海市支持会展业发展资金管理暂行办法（修订稿）",
    "content": "根据2023年《琼海市支持会展业发展资金管理暂行办法（修订稿）》中显示：\n国际性或全国性的展览会（不包括各类嘉年华活动，夜市经济活动，面向市民的展销会等），展期3天（不含布撤展），主体展览面积（不含配套开幕式、产品或技术现场推介会、培训会、演艺、餐饮等面积）5000平方米或实际展位不少于300个国际标准展位（特装展位根据实际展览面积乘以0.5的系数折算成标准展位数）的展览会，按照每个标准展位（实际参展）600元/天给予补贴。单个项目补贴总额最高不超过80万元。",
    "url": "http://qionghai.hainan.gov.cn/zfxxgkzl/bm/bgt/gkml/202306/t20230613_3434781.html"
}, {
    "province": "重庆市",
    "time": "2023-02-02",
    "title": "重庆企业提升国际化经营能力项目申报指南",
    "content": "外贸企业境外参展支持标准：企业参加\u201c一带一路\u201d沿线国家展会的展位费按最高不超过90%的比例给予支持，参加其他境内外线下展的展位费按最高不超过70%的比例给予支持。\n更详细内容可参考《重庆企业提升国际化经营能力项目申报指南》，申报指南可与所在区县商务委联系获取和咨询。",
    "url": "https://sww.cq.gov.cn/igixmj/xjgk/202302/tGovMsgBox_855796.html"
}, {
    "province": "海南省",
    "time": "2024-08-09",
    "title": "海南开展省级会展项目申报工作 这四类专业展览或会议可申报",
    "content": "省商务厅近日发出通知，开展2025年度省级会展项目申报工作，计划于2025年度在我省举办的四类专业展览或会议可提出申报。\n\n\n\n" +
        "四类专业展览或会议为：省政府重点培育的现有品牌专业展览或会议；首次在我省举办列全国同行业内规模前三名的专业展览（参考中国会展经济研究会2023年度《中国展览数据统计报告》细分行业排名）；首次在我省举办有4个及以上国家或地区（不含我国香港、澳门、台湾地区）代表现场参会，且高端嘉宾总人数达8人及以上的高端国际专业会议（不含境内党政机关工作性会议、联欢联谊类会议以及节庆、赛事类活动等）；2020年9月以来列入省级会展支持计划且获得省级会展资金支持不超过三届的现有专业展览或会议。\n" +
        "申报单位须于今年9月30日前按照项目主题性质，向相应的省级产业主管部门提交申报材料。其中，商贸行业类专业展览或会议须征得省商务厅相关业务处室同意后，向省贸促会申报。\n" +
        "据了解，海南安排资金支持会展业发展，符合有关条件的展览或会议最高可获700万元奖励。根据《海南省支持会展业发展资金管理办法》，省级会展资金重点支持引进国内外重大会展品牌，大力培育省内现有重点专业展览或会议，积极打造一批国内外知名会展品牌。按照《2024年度省级会展支持计划》，今年海南计划支持30个省级会展项目。\"\n",
    "url": "https://dofcom.hainan.gov.cn/dofcom/xctp/202408/b204d9aee03949ce8424548186ee712c.shtml"
}, {
    "province": "北京市",
    "time": "2024-06-13",
    "title": "关于申报2024年促进我市会展业发展奖励项目的通知",
    "content": "根据《中山市人民政府关于印发中山市2024年新春暖企促消费十八条的通知》（中府〔2024〕31号）、《中山市商务局关于印发〈中山市2023年“百展千企”拓市场扶持政策〉的通知》（中山商务贸字〔2024〕12号），现制定扶持政策资金申报指南如下：\n" +
        "　　一、支持期限\n" +
        "　　2024年1月1日至2024年12月31日。\n" +
        "　　二、支持对象\n" +
        "　　在我市依法设立，守法经营，且参与《中山市2024年境外展览目录》展会的相关行业商协会及法人企业。\n" +
        "　　三、支持内容及标准\n" +
        "　　（一）往返机票费用补助\n" +
        "　　1.对参与市领导率团组织的重点展会（含境外参展、商务洽谈、搭建海外营销等，下同）的企业（含行业商协会，下同），根据境外目的地的不同（详见下表），按照每场展会每人不超过15000元给予往返机票补助，每场展会每家企业补助不超过3人，且不高于企业限额人数内实际支出的往返机票费用总额。\n" +
        "　　2.对参与市直商务部门（含市贸促会）、镇街带队组织的目标展会的企业，根据境外目的地的不同，按照每场展会每人不超过10000元给予往返机票补助，每场展会每家企业补助不超过3人，且不高于企业限额人数内实际支出的往返机票费用总额。\n" +
        "　　3.对参与行业商协会、展览公司等组织的一般展会（同一展会中山企业备案且参展数不低于10家）的企业，根据境外目的地的不同，按照每场展会每人不超过6000元给予往返机票补助，每场展会每家企业补助不超过3人，且不高于企业限额人数内实际支出的往返机票费用总额。",
    "url": "https://sw.beijing.gov.cn/zwxx/2024zcwj/202406/t20240621_3722909.html"
}, {
    "province": "广东省",
    "time": "2024-02-27",
    "title": "中山市商务局关于印发《2024年中山市“百展千企”拓市场扶持政策申报指南》的通知",
    "content": "根据《中山市人民政府关于印发中山市2024年新春暖企促消费十八条的通知》（中府〔2024〕31号）、《中山市商务局关于印发〈中山市2023年“百展千企”拓市场扶持政策〉的通知》（中山商务贸字〔2024〕12号），现制定扶持政策资金申报指南如下：\n" +
        "　　一、支持期限\n2024年1月1日至2024年12月31日。\n" +
        "　　二、支持对象\n在我市依法设立，守法经营，且参与《中山市2024年境外展览目录》展会的相关行业商协会及法人企业。\n" +
        "　　三、支持内容及标准\n" +
        "　　（一）往返机票费用补助\n" +
        "　　1.对参与市领导率团组织的重点展会（含境外参展、商务洽谈、搭建海外营销等，下同）的企业（含行业商协会，下同），根据境外目的地的不同（详见下表），按照每场展会每人不超过15000元给予往返机票补助，每场展会每家企业补助不超过3人，且不高于企业限额人数内实际支出的往返机票费用总额。\n" +
        "　　2.对参与市直商务部门（含市贸促会）、镇街带队组织的目标展会的企业，根据境外目的地的不同，按照每场展会每人不超过10000元给予往返机票补助，每场展会每家企业补助不超过3人，且不高于企业限额人数内实际支出的往返机票费用总额。\n" +
        "　　3.对参与行业商协会、展览公司等组织的一般展会（同一展会中山企业备案且参展数不低于10家）的企业，根据境外目的地的不同，按照每场展会每人不超过6000元给予往返机票补助，每场展会每家企业补助不超过3人，且不高于企业限额人数内实际支出的往返机票费用总额。",
    "url": "https://www.zs.gov.cn/zsswj/gkmlpt/content/2/2377/post_2377293.html#1184"
}, {
    "province": "浙江省",
    "time": "2024-01-09",
    "title": "湖州市商务局 湖州市财政局 中国国际贸易促进委员会湖州市委员会关于公布2024年度展会目录的通知",
    "content": "各区县商务局(贸促会)，南太湖新区经济发展局，各相关企业：\n" +
        "为贯彻落实全省“地瓜经济”提能升级“一号开放工程”工作部署和《全力拓市场增订单稳外贸若干措施》工作要求，解决企业在拓市场、抢订单、促生产等方面面临的突出问题，促进全市外贸稳增提质，经研究确定了 2024年度我市展会目录，现予以公布，并就有关事项通知如下:\n" +
        "一、总体概况\n" +
        "2024年拟重点组织和支持的境内外展会共150个，包括：重点组织类境外展 27 个、重点支持类境外展 87个、重点支持类境内国际性展 36个。对参加上述展会项目的市本级企业，按照展会项目分类对企业发生的展位费、人员费予以一定资助，具体资助标准按照 2024年度省、市外贸扶持政策执行。对商务部、国家各进出口商会省商务厅、市委市政府等有关临时通知组织参加的重点国际市场拓展活动，原则上视为市重点支持类境内外参展活动。\n" +
        "二、做好展会组织管理\n" +
        "全市各级商务主管部门要积极发动、广泛宣传、全力推动企业走出去拓市场、抢订单。各展会组织单位要强化合作意识，不得恶性竞争，并努力提高服务水平，面向参展企业积极开展展会营销及策略等方面技能培训，加强参展期间人身、财物等方面安全工作，做好展会客商、订单情况、市场情况、企业反映等方面的统计调查工作，并指导企业做好展会扶持政策申报工作。各参展企业要努力以自主品牌参加境内外展会，增强知识产权保护意识，坚决杜绝侵犯他人知识产权现象，并重视布展与宣传，突出品牌与企业形象，\n" +
        "三、开展展会绩效评估\n" +
        "每年市商务局将对展会承办单位按工作开展、任务完成、展前展中及展后服务、参展企业反映、规范诚信运作等进行年度考评，综合评估展会承办成效，并作为下一年展会承办资格认定的依据。各展会承办单位要在展会结束 20天内，将展会总结（包括组展情况、参展数据和有关调查分析等）报送至市商务局。对在考评中发现工作不佳的承办单位将在下一年度承办认定中予以更换，对发现违法违规操作的承办单位暂停或取消承办资格。市财政局、市商务局将定期对各级国际性展会补助资金落实情况进行总结和绩效评价，确保各级补助资金及时兑现到位，提高资金使用绩效。\n",
    "url": "https://swj.huzhou.gov.cn/art/2024/1/9/art_1229705642_58941673.html"
}]
export const hzzczc = [{
    "province": "山西",
    "time": "2023-07-01",
    "title": "全省服务贸易开放创新发展实施方案",
    "url": "http://swt.shanxi.gov.cn/xxgk/xxgkzl/xxgklm/gztz_69874/202307/t20230701_8844755.shtml"
}, {
    "province": "广东",
    "city": "广州",
    "time": "2023-06-26",
    "title": "广东省商务厅关于下达省级2023年促进经济高质量发展专项资金（重点展会和展会配套体系）项目计划的通知",
    "url": "http://sw.gz.gov.cn/gkmlpt/content/9/9068/post_9068747.html#147"
}, {
    "province": "广东",
    "city": "广州",
    "time": "2023-03-07",
    "title": "广州市商务局关于印发2023年广州市促进商务高质量发展专项资金会展业专题项目库（第一批）申报指南的通知",
    "url": "http://sw.gz.gov.cn/gkmlpt/content/8/8837/mpost_8837287.html#147"
}, {
    "province": "广东",
    "city": "深圳",
    "time": "2023-04-07",
    "title": "深圳市商务局关于发布会展业发展扶持计划（2021年度市外国内展会重点扶持计划资助项目）拟资助计划公示的通知",
    "url": "http://commerce.sz.gov.cn/gkmlpt/content/10/10529/post_10529365.html#524"
}, {
    "province": "广东",
    "city": "深圳",
    "time": "2023-03-15",
    "title": "深圳市商务局关于发布2023会展业发展扶持计划（稳增长政策项目）拟资助计划公示的通知",
    "url": "http://commerce.sz.gov.cn/gkmlpt/content/10/10481/post_10481836.html#524"
}, {
    "province": "广东",
    "city": "深圳",
    "time": "2023-01-12",
    "title": "深圳市商务局关于实施阶段性支持商贸经济运行若干措施的通知",
    "url": "http://commerce.sz.gov.cn/xxgk/qt/tzgg_1/content/post_10379057.html?eqid=b0a37b5500024c6000000006643e0433"
}, {
    "province": "江苏",
    "time": "2023-07-03",
    "title": "省商务厅关于组织参加2023年第二批服务贸易境外线下重点展会的通知",
    "url": "http://doc.jiangsu.gov.cn/art/2023/7/3/art_78712_10940326.html"
}, {
    "province": "北京",
    "city": "石景山",
    "time": "2023-06-02",
    "title": "石景山区全面优化营商环境助力企业高质量发展实施方案",
    "url": "http://www.bjsjs.gov.cn/gongkai/zwgkpd/zcwj_1940/202306/t20230615_71086.shtml"
}, {
    "province": "北京",
    "city": "顺义",
    "time": "2023-02-09",
    "title": "顺义区鼓励会展产业集聚发展扶持办法（试行）",
    "url": "http://www.bjshy.gov.cn/web/ztlm/zt/sylqjs/qwfb/1314211/index.html"
}, {
    "province": "上海",
    "time": "2023-06-07",
    "title": "第六届中国国际进口博览会市场监管服务保障工作实施方案",
    "url": "https://www.shanghai.gov.cn/gwk/search/content/2c984ad688753251018894ee17df2517"
}, {
    "province": "上海",
    "city": "浦东",
    "time": "2023-05-16",
    "title": "浦东新区推动外贸稳规模促创新拓市场优环境的实施方案",
    "url": "https://www.shanghai.gov.cn/hqfzhj3/20230625/f29fbee2284e4f7cae1885e32b3687a4.html"
}, {
    "province": "上海",
    "city": "浦东",
    "time": "2023-05-08",
    "title": "浦东新区强动能增活力优服务 深入推动高质量发展行动方案",
    "url": "http://service.shanghai.gov.cn/XingZhengWenDangKuJyh/XZGFDetails.aspx?docid=23051809200478z2iTw6w2zyaphz11a"
}, {
    "province": "天津",
    "time": "2023-05-29",
    "title": "天津市人民代表大会常务委员会关于促进和保障国际消费中心城市建设的决定",
    "url": "https://flk.npc.gov.cn/detail2.html?ZmY4MDgxODE4ODMyMWMwYTAxODg3NWUzMGJmZDY1YzA%3D"
}, {
    "province": "重庆",
    "time": "2023-05-25",
    "title": "重庆市商务委员会关于印发促进会展业发展支持政策的通知",
    "url": "https://sww.cq.gov.cn/zwgk_247/zfxxgkmlrk/zcwj/xzffxwj/202305/t20230530_12011102.html"
}, {
    "province": "贵州",
    "city": "毕节",
    "time": "2023-05-30",
    "title": "毕节市项目建设年活动实施方案",
    "url": "https://www.bijie.gov.cn/bm/bjsczj/zwgk/zcwjjzcjd/202305/t20230530_79964170.html"
}, {
    "province": "湖北",
    "city": "武汉",
    "time": "2023-02-09",
    "title": "武汉市会展业发展专项资金管理办法（试行）",
    "url": "https://www.wuhan.gov.cn/gfxwj/sbmgfxwj/sswj_79505/202302/t20230209_2149103.shtml"
}, {
    "province": "湖北",
    "city": "武汉",
    "time": "2023-02-06",
    "title": "关于激发市场主体活力推动经济高质量发展的政策措施",
    "url": "https://www.wuhan.gov.cn/zwgk/xxgk/zfwj/gfxwj/202302/t20230206_2146523.shtml"
}, {
    "province": "海南",
    "time": "2023-04-28",
    "title": "2023年度省级会展支持计划",
    "url": "https://dofcom.hainan.gov.cn/dofcom/0400/202304/2f37734ebfa24edcbfe5b72f9cb21ae0.shtml"
}, {
    "province": "山西",
    "time": "2023-05-10",
    "title": "2023年国际市场开拓\u201c千企百展\u201d行动计划",
    "url": "http://swt.shanxi.gov.cn/zwgk/gkml/202305/t20230510_8512390.shtml"
}, {
    "province": "湖南",
    "city": "长沙",
    "time": "2023-04-24",
    "title": "长沙市关于 打好经济增长主动仗推动经济高质量发展的若干政策措施",
    "url": "http://mch.changsha.gov.cn/xxgk/fdzdgknr/zcfg2/gfxwj/202305/t20230504_11084863.html"
}, {
    "province": "全国",
    "time": "2023-04-15",
    "title": "关于2023年中国进出口商品交易会展期内销售的进口展品税收优惠政策的通知",
    "url": "http://gss.mof.gov.cn/gzdt/zhengcefabu/202304/t20230414_3879002.htm"
}, {
    "province": "四川",
    "time": "2023-05-18",
    "title": "国家税务总局四川省税务局 四川省财政厅关于落实交通运输等五个行业纳税人免征2023年上半年房产税 城镇土地使用税的公告",
    "url": "https://sichuan.chinatax.gov.cn/art/2023/5/18/art_7269_900782.html"
}, {
    "province": "宁夏",
    "time": "2023-04-18",
    "title": "宁夏回族自治区外资三年外贸五年倍增计划",
    "url": "https://dofcom.nx.gov.cn/zcfb/nxzc/202304/t20230418_4034959.html"
}, {
    "province": "安徽",
    "time": "2023-03-31",
    "title": "国家税务总局安徽省税务局关于\u201c六大行业\u201d纳税人和增值税小规模纳税人免征2023年上半年房产税和城镇土地使用税有关事项的通告",
    "url": "http://anhui.chinatax.gov.cn/art/2023/3/31/art_13773_63048.html?eqid=c1ac917800044cf4000000066449cd45"
}, {
    "province": "安徽",
    "city": "合肥",
    "time": "2023-01-10",
    "title": "关于申报2022年支持高质量发展政策（商务）跨年度条款的通知",
    "url": "https://swj.hefei.gov.cn/public/18311/108535111.html"
}, {
    "province": "海南",
    "time": "2023-03-23",
    "title": "关于促进海南文体会展活动恢复振兴的若干措施",
    "url": "http://en.hainan.gov.cn/hainan/szfbgtwj/202303/8a3800906f9a4df482348df507acfd5b.shtml"
}, {
    "province": "山东",
    "time": "2023-03-14",
    "title": "关于促进内外贸一体化发展持续打造对外开放新高地的若干措施",
    "url": "http://www.shandong.gov.cn/art/2023/3/14/art_267492_44763.html"
}]
